import { getField, updateField } from 'vuex-map-fields'
import requests from '~/requests'
import {
  BILLINGS_URL,
  REPORTS_URL,
  CHAT_REPORT_SERVICE_URL,
} from '~/assets/variables/endpoints'

export const state = () => ({
  isLoadingMuv: false,
  isLoadingMcc: false,
  isLoadingBroadcast: false,
  data: {
    created_at: null,
    channel_number: null,
    cust_number: null,
    country_code: null,
    cust_name: null,
    room_id: null,
    type: null,
  },
  response_mcc: [],
  response_muv: [],
  response_broadcast: [],
  filter: {
    start_at: Math.trunc(
      new Date().getTime() + (new Date().getTimezoneOffset() / 60) * 3600000
    ),
    end_at: Math.trunc(
      new Date().getTime() + (new Date().getTimezoneOffset() / 60) * 3600000
    ),
  },
  pagination: {
    mcc: {
      current: 1,
      limit: 10,
      total_page: 0,
      total: 0,
      next: false,
      prev: true,
    },
    muv: {
      current: 1,
      limit: 10,
      total_page: 0,
      total: 0,
      next: false,
      prev: true,
    },
    broadcast: {
      current: 1,
      limit: 10,
      total_page: 0,
      total: 0,
      next: false,
      prev: true,
    },
  },
})

export const getters = {
  getField,
}

export const actions = {
  getData({ commit }, params) {
    commit('UPDATE_LOADING_MCC', true)
    commit('UPDATE_LOADING_MUV', true)
    commit('UPDATE_LOADING_BROADCAST', true)
    return new Promise((resolve, reject) => {
      if (params.type === 'mcc') {
        requests.whatsapp
          .getService(`${REPORTS_URL}/billing/mcc_logs`, params.payload, {
            Authorization: this.$auth.getToken('hub'),
          })
          .subscribe(
            (res) => {
              commit('SET_DATA_MCC', res.data)
              commit('UPDATE_PAGINATION_PAGE', {
                type: params.type,
                current: res.meta.pagination.offset,
                total: res.meta.pagination.total,
                limit: params.payload.limit,
              })
              commit('UPDATE_LOADING_MCC', false)
              resolve(true)
            },
            (err) => {
              commit('SET_DATA_MCC', [])
              commit('UPDATE_LOADING_MCC', false)
              reject(err)
            }
          )
      } else if (params.type === 'muv') {
        requests.whatsapp
          .getService(`${REPORTS_URL}/billing/muv_logs`, params.payload, {
            Authorization: this.$auth.getToken('hub'),
          })
          .subscribe(
            (res) => {
              commit('SET_DATA_MUV', res.data.response)
              commit('UPDATE_PAGINATION_PAGE', {
                type: params.type,
                current: res.data.meta.offset,
                total: res.data.meta.total,
                limit: params.payload.limit,
              })
              commit('UPDATE_LOADING_MUV', false)
              resolve(true)
            },
            (err) => {
              commit('UPDATE_PAGINATION_PAGE', {
                type: params.type,
                current: 1,
                total: 0,
                limit: 10,
              })
              commit('SET_DATA_MUV', [])
              commit('UPDATE_LOADING_MUV', false)
              reject(err)
            }
          )
      } else if (params.type === 'broadcast') {
        requests.whatsapp
          .getService(`${BILLINGS_URL}/broadcast_deduction`, params.payload, {
            Authorization: this.$auth.getToken('hub'),
          })
          .subscribe(
            (res) => {
              commit('SET_DATA_BROADCAST', res.data.response)
              commit('UPDATE_PAGINATION_PAGE', {
                type: params.type,
                current: res.data.meta.offset,
                total: res.data.meta.total,
                limit: params.payload.limit,
              })
              commit('UPDATE_LOADING_BROADCAST', false)
              resolve(true)
            },
            (err) => {
              commit('UPDATE_PAGINATION_PAGE', {
                type: params.type,
                current: 1,
                total: 0,
                limit: 10,
              })
              commit('SET_DATA_BROADCAST', [])
              commit('UPDATE_LOADING_BROADCAST', false)
              reject(err)
            }
          )
      }
    })
  },
  updateData({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params.type === 'mcc') {
        commit('UPDATE_LOADING_MCC', true)
        requests.whatsapp
          .getService(`${REPORTS_URL}/billing/mcc_logs`, params.payload, {
            Authorization: this.$auth.getToken('hub'),
          })
          .subscribe(
            (res) => {
              commit('SET_DATA_MCC', res.data)
              commit('UPDATE_PAGINATION_PAGE', {
                type: params.type,
                current: res.meta.pagination.offset,
                total: res.meta.pagination.total,
                limit: params.payload.limit,
              })
              commit('UPDATE_LOADING_MCC', false)
              resolve(true)
            },
            (err) => {
              commit('SET_DATA_MCC', [])
              commit('UPDATE_LOADING_MCC', false)
              reject(err)
            }
          )
      } else if (params.type === 'muv') {
        commit('UPDATE_LOADING_MUV', true)
        requests.whatsapp
          .getService(`${REPORTS_URL}/billing/muv_logs`, params.payload, {
            Authorization: this.$auth.getToken('hub'),
          })
          .subscribe(
            (res) => {
              commit('SET_DATA_MUV', res.data.response)
              commit('UPDATE_PAGINATION_PAGE', {
                type: params.type,
                current: res.data.meta.offset,
                total: res.data.meta.total,
                limit: params.payload.limit,
              })
              commit('UPDATE_LOADING_MUV', false)
              resolve(true)
            },
            (err) => {
              commit('SET_DATA_MUV', [])
              commit('UPDATE_PAGINATION_PAGE', {
                type: params.type,
                current: 1,
                total: 0,
                limit: 10,
              })
              commit('SET_DATA_MUV', [])
              commit('UPDATE_LOADING_MUV', false)
              reject(err)
            }
          )
      } else if (params.type === 'broadcast') {
        commit('UPDATE_LOADING_BROADCAST', true)
        requests.whatsapp
          .getService(`${BILLINGS_URL}/broadcast_deduction`, params.payload, {
            Authorization: this.$auth.getToken('hub'),
          })
          .subscribe(
            (res) => {
              commit('SET_DATA_BROADCAST', res.data.response)
              commit('UPDATE_PAGINATION_PAGE', {
                type: params.type,
                current: res.data.meta.offset,
                total: res.data.meta.total,
                limit: params.payload.limit,
              })
              commit('UPDATE_LOADING_BROADCAST', false)
              resolve(true)
            },
            (err) => {
              commit('SET_DATA_BROADCAST', [])
              commit('UPDATE_PAGINATION_PAGE', {
                type: params.type,
                current: 1,
                total: 0,
                limit: 10,
              })
              commit('SET_DATA_BROADCAST', [])
              commit('UPDATE_LOADING_BROADCAST', false)
              reject(err)
            }
          )
      }
    })
  },
  getCountData({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params.type === 'mcc') {
        requests.whatsapp
          .getService(`${REPORTS_URL}/billing/mcc_logs`, params.payload, {
            Authorization: this.$auth.getToken('hub'),
          })
          .subscribe(
            (res) => {
              commit('UPDATE_PAGINATION_TOTAL', {
                type: params.type,
                total: res.meta.pagination.total,
                limit: params.payload.limit,
              })
              resolve(true)
            },
            (err) => {
              reject(err)
            }
          )
      } else if (params.type === 'muv') {
        requests.whatsapp
          .getService(`${REPORTS_URL}/billing/muv_logs`, params.payload, {
            Authorization: this.$auth.getToken('hub'),
          })
          .subscribe(
            (res) => {
              commit('UPDATE_PAGINATION_TOTAL', {
                type: params.type,
                total: res.data.meta.total,
                limit: params.payload.limit,
              })
              resolve(true)
            },
            (err) => {
              commit('UPDATE_PAGINATION_PAGE', {
                type: params.type,
                current: 1,
                total: 0,
                limit: 10,
              })
              commit('UPDATE_PAGINATION_BUTTON', {
                type: params.type,
                action: 'initial',
              })
              reject(err)
            }
          )
      } else if (params.type === 'broadcast') {
        requests.whatsapp
          .getService(`${BILLINGS_URL}/broadcast_deduction`, params.payload, {
            Authorization: this.$auth.getToken('hub'),
          })
          .subscribe(
            (res) => {
              commit('UPDATE_PAGINATION_TOTAL', {
                type: params.type,
                total: res.data.meta.total,
                limit: params.payload.limit,
              })
              resolve(true)
            },
            (err) => {
              commit('UPDATE_PAGINATION_PAGE', {
                type: params.type,
                current: 1,
                total: 0,
                limit: 10,
              })
              commit('UPDATE_PAGINATION_BUTTON', {
                type: params.type,
                action: 'initial',
              })
              reject(err)
            }
          )
      }
    })
  },
  updateButton({ commit }, params) {
    commit('UPDATE_PAGINATION_BUTTON', {
      type: params.type,
      action: params.action,
    })
  },
  updateChangePagination({ commit }, params) {
    commit('UPDATE_CHANGE_PAGINATION', { type: params.type, e: params.e })
  },
  exportLogData({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(
        'layouts/DISPLAY_TOAST',
        { message: 'Download will start automatically..' },
        { root: true }
      )
      if (params.type === 'broadcast') {
        const payload = {
          ...params.payload,
          organization_id: this.$auth.user.organization_id,
        }
        requests.whatsapp
          .getService(`${BILLINGS_URL}/download_broadcast_deduction`, payload, {
            Authorization: this.$auth.getToken('hub'),
          })
          .subscribe(
            (res) => {
              const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' })
              if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, 'my_data.csv')
              } else {
                const link = document.createElement('a')
                if (link.download !== undefined) {
                  const filename = `report_${params.type}_logs_${params.raw_date.start_date}_${params.raw_date.end_date}.csv`
                  const url = URL.createObjectURL(blob)
                  link.setAttribute('href', url)
                  link.setAttribute('download', filename)
                  link.style.visibility = 'hidden'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }
              }
              resolve(true)
            },
            (err) => {
              commit(
                'layouts/DISPLAY_TOAST',
                { message: 'Failed to download ' + err.error.messages[0] },
                { root: true }
              )
              reject(err)
            }
          )
      } else {
        requests.whatsapp
          .getService(
            `${REPORTS_URL}/billing/download/core/${params.type}_logs`,
            params.payload,
            { Authorization: this.$auth.getToken('hub') }
          )
          .subscribe(
            (res) => {
              const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' })
              if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, 'my_data.csv')
              } else {
                const link = document.createElement('a')
                if (link.download !== undefined) {
                  const filename = `report_${params.type}_logs_${params.raw_date.start_date}_${params.raw_date.end_date}.csv`
                  const url = URL.createObjectURL(blob)
                  link.setAttribute('href', url)
                  link.setAttribute('download', filename)
                  link.style.visibility = 'hidden'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }
              }
              resolve(true)
            },
            (err) => {
              commit(
                'layouts/DISPLAY_TOAST',
                { message: 'Failed to get data. Please try again later' },
                { root: true }
              )
              reject(err)
            }
          )
      }
    })
  },
  getDataAthena({ commit }, params) {
    return new Promise((resolve, reject) => {
      if (params.type === 'mcc') {
        commit('UPDATE_LOADING_MCC', true)
        const parameters =
          Object.entries(params.payload)
            .map(([k, v]) => `${k}=${v}`)
            .join('&') || ''

        this.$axios
          .get(
            `${CHAT_REPORT_SERVICE_URL}/billings/mcc_logs?${parameters}`,
            params.payload,
            {
              Authorization: this.$auth.getToken('hub'),
            }
          )
          .then((res) => {
            commit('SET_DATA_MCC', res.data?.data)
            commit('UPDATE_PAGINATION_PAGE', {
              type: params.type,
              current: res.data?.pagination?.page,
              total: res.data?.pagination?.total,
              limit: res.data?.pagination?.per_page,
            })
            commit('UPDATE_LOADING_MCC', false)
            resolve(true)
          })
          .catch((err) => {
            commit('SET_DATA_MCC', [])
            console.error(err)
          })
      }

      if (params.type === 'muv') {
        commit('UPDATE_LOADING_MUV', true)
        const parameters =
          Object.entries(params.payload)
            .map(([k, v]) => `${k}=${v}`)
            .join('&') || ''

        this.$axios
          .get(
            `${CHAT_REPORT_SERVICE_URL}/billings/muv_logs?${parameters}`,
            params.payload,
            {
              Authorization: this.$auth.getToken('hub'),
            }
          )
          .then((res) => {
            commit('SET_DATA_MUV', res.data?.data)
            commit('UPDATE_PAGINATION_PAGE', {
              type: params.type,
              current: res.data?.pagination?.page,
              total: res.data?.pagination?.total,
              limit: res.data?.pagination?.per_page,
            })
            commit('UPDATE_LOADING_MUV', false)
            resolve(true)
          })
          .catch((err) => {
            commit('SET_DATA_MUV', [])
            console.error(err)
          })
      }

      if (params.type === 'broadcast') {
        commit('UPDATE_LOADING_BROADCAST', true)
        const parameters =
          Object.entries(params.payload)
            .map(([k, v]) => `${k}=${v}`)
            .join('&') || ''

        this.$axios
          .get(
            `${CHAT_REPORT_SERVICE_URL}/billings/broadcast_deductions?${parameters}`,
            params.payload,
            {
              Authorization: this.$auth.getToken('hub'),
            }
          )
          .then((res) => {
            commit('SET_DATA_BROADCAST', res.data?.data)
            commit('UPDATE_PAGINATION_PAGE', {
              type: params.type,
              current: res.data?.pagination?.page,
              total: res.data?.pagination?.total,
              limit: res.data?.pagination?.per_page,
            })
            commit('UPDATE_LOADING_BROADCAST', false)
            resolve(true)
          })
          .catch((err) => {
            commit('SET_DATA_BROADCAST', [])
            console.error(err)
          })
      }
    })
  },
  exportLogDataAthena({ commit }, params) {
    const prefixUrl = {
      broadcast: 'broadcast_deductions',
      mcc: 'mcc_logs',
      muv: 'muv_logs',
    }

    return new Promise((resolve, reject) => {
      const parameters =
        Object.entries(params.payload)
          .map(([k, v]) => `${k}=${v}`)
          .join('&') || ''

      this.$axios
        .get(
          `${CHAT_REPORT_SERVICE_URL}/billings/export/${
            prefixUrl[params.type]
          }?${parameters}`,
          {
            responseType: 'arraybuffer',
            headers: {
              Authorization: this.$auth.getToken('hub'),
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          const type = res.headers['content-type']
          const blob = new Blob([res.data], {
            type,
            encoding: 'UTF-8',
          })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          const filename = `report_${params.type}_logs_${params.payload.start_date}_${params.payload.end_date}.csv`
          link.download = filename
          link.click()
          resolve(true)
        })
        .catch((error) => {
          reject(error.toJSON())
        })
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_LOADING_MCC(state, payload) {
    state.isLoadingMcc = payload
  },
  UPDATE_LOADING_MUV(state, payload) {
    state.isLoadingMuv = payload
  },
  UPDATE_LOADING_BROADCAST(state, payload) {
    state.isLoadingBroadcast = payload
  },
  SET_DATA_MCC(state, payload) {
    state.response_mcc = payload
  },
  SET_DATA_MUV(state, payload) {
    state.response_muv = payload
  },
  SET_DATA_BROADCAST(state, payload) {
    state.response_broadcast = payload
  },
  UPDATE_FILTER(state, payload) {
    state.filter.start_at = payload.start_at
    state.filter.end_at = payload.end_at
  },
  UPDATE_PAGINATION_PAGE(state, payload) {
    state.pagination[payload.type].limit = payload.limit
    state.pagination[payload.type].total = payload.total
    state.pagination[payload.type].total_page = Math.ceil(
      payload.total / payload.limit
    )
    state.pagination[payload.type].current = payload.current
  },
  UPDATE_PAGINATION_BUTTON(state, payload) {
    if (payload.action === 'next') {
      if (state.pagination[payload.type].current)
        state.pagination[payload.type].prev = false
      if (
        state.pagination[payload.type].current >=
        state.pagination[payload.type].total_page
      )
        state.pagination[payload.type].next = true
    } else if (payload.action === 'initial') {
      state.pagination[payload.type].next =
        state.pagination[payload.type].current >=
        state.pagination[payload.type].total_page
      if (state.pagination[payload.type].current <= 1)
        state.pagination[payload.type].prev = true
    } else {
      if (state.pagination[payload.type].current <= 1)
        state.pagination[payload.type].prev = true
      if (state.pagination[payload.type].current)
        state.pagination[payload.type].next = false
    }
  },
  UPDATE_CHANGE_PAGINATION(state, payload) {
    state.pagination[payload.type].prev = payload.e <= 1
    state.pagination[payload.type].next =
      payload.e >= state.pagination[payload.type].total_page
  },
  UPDATE_PAGINATION_TOTAL(state, payload) {
    state.pagination[payload.type].total_page = Math.ceil(
      payload.total / payload.limit
    )
  },
}
