import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import { BILLINGS_URL } from '~/assets/variables/endpoints'

export const state = () => ({
  response_config: [],
  package_info: [],
})

export const getters = {
  getField,
}

export const actions = {
  sendOrderTopup({ commit }, params) {
    const items = {
      items: JSON.stringify(params.items),
    }
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${BILLINGS_URL}/orders`, items, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getDefaultConfig({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${BILLINGS_URL}/orders/configs`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_DATA', res.data.configs)
          },
          (err) => {
            reject(err)
          }
        )
      requests.whatsapp
        .getService(
          `${BILLINGS_URL}/info`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_CONFIG', res.data)
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_DATA(state, payload) {
    state.response_config = payload
  },
  UPDATE_CONFIG(state, payload) {
    state.package_info = payload
  },
}
