import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import {
  REPORTS_URL,
  CHAT_REPORT_SERVICE_URL,
} from '~/assets/variables/endpoints'

export const state = () => ({
  chartData: {
    status: 'loading',
    labels: [],
    datasets: [],
  },
})

export const getters = {
  getField,
}

export const actions = {
  getDataUsage({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${REPORTS_URL}/billing/daily_muv_mcc`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('SET_DATA_CHART', res.data)
            resolve(true)
          },
          (err) => {
            reject(err)
            resolve(false)
          }
        )
    })
  },
  updateDataChart({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${REPORTS_URL}/billing/daily_muv_mcc`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('SET_DATA_CHART', res.data)
            resolve(true)
          },
          (err) => {
            reject(err)
            resolve(false)
          }
        )
    })
  },
  getDataUsageAthena({ commit }, params) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`${CHAT_REPORT_SERVICE_URL}/billings/usage_trends`, {
          headers: {
            Authorization: this.$auth.getToken('hub'),
            'Content-Type': 'application/json',
          },
          params,
        })
        .then((res) => {
          commit('SET_DATA_CHART', res?.data?.data[0])
          resolve(true)
        })
        .catch((error) => {
          reject(error.toJSON())
        })
    })
  },
}

export const mutations = {
  updateField,
  SET_DATA_CHART(state, payload) {
    state.chartData.labels = []
    state.chartData.datasets = []
    state.chartData.status = 'loading'

    if (payload) {
      // If using new wa price schema
      const toggleNewPrice =
        payload?.bi !== undefined && payload?.ui !== undefined

      if (toggleNewPrice) {
        const uiDefault = {
          label: 'Service conversations',
          data: [],
        }
        payload.ui.forEach((ui) => {
          uiDefault.data.push(ui)
        })

        const biDefault = {
          label: 'Business-initiated (BI) conversations',
          data: [],
        }
        payload.bi.forEach((bi) => {
          biDefault.data.push(bi)
        })

        const muvDefault = {
          label: 'Unique Visitor',
          data: [],
        }
        payload.muv.forEach((muv) => {
          muvDefault.data.push(muv)
        })
        state.chartData.datasets.push(uiDefault, biDefault, muvDefault)
        state.chartData.labels = payload.date_labels
        if (
          (muvDefault.data.length ||
            biDefault.data.length ||
            uiDefault.data.length) > 0
        ) {
          state.chartData.status = 'success'
        } else {
          state.chartData.status = 'failed'
        }
      } else {
        const mccDefault = {
          label: 'Whatsapp Conversation',
          data: [],
        }
        payload.mccs.forEach((mcc) => {
          mccDefault.data.push(mcc)
        })

        const muvDefault = {
          label: 'Unique Visitor',
          data: [],
        }
        payload.muvs.forEach((muv) => {
          muvDefault.data.push(muv)
        })

        state.chartData.datasets.push(mccDefault, muvDefault)
        state.chartData.labels = payload.date_labels
        if ((muvDefault.data.length || mccDefault.data.length) > 0) {
          state.chartData.status = 'success'
        } else {
          state.chartData.status = 'failed'
        }
      }
    } else {
      state.chartData.labels = []
      state.chartData.datasets = []
      state.chartData.status = 'failed'
    }
  },
}
