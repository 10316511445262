import requests from '../../requests'
import { BILLINGS_URL } from '~/assets/variables/endpoints'

export const state = () => ({
  isOpenModalFreeze: false,
  isExpiredEcommerce: false,
  ecommerceStoreQuota: 0,
  isOpenModalLimitQuota: false,
})

export const getters = {
  isOpenModalFreeze(state) {
    return state.isInitiated
  },
}

export const actions = {
  checkExpiredEcommerceBilling({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${BILLINGS_URL}/ecommerce/check_expired_channel`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_STATUS_EXPIRED_ECOMMERCE', res?.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  checkQuotaStoreEcommerce({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${BILLINGS_URL}/ecommerce/balance`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_QUOTA_ECOMMERCE', res?.data?.remaining_integration)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  setIsNewEcommerce({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(`${BILLINGS_URL}/ecommerce/is_new_ecommerce`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  UPDATE_MODAL_FREEZE(state, payload) {
    state.isOpenModalFreeze = payload
  },
  UPDATE_STATUS_EXPIRED_ECOMMERCE(state, payload) {
    state.isExpiredEcommerce = payload
  },
  UPDATE_QUOTA_ECOMMERCE(state, payload) {
    state.ecommerceStoreQuota = payload
  },
  UPDATE_MODAL_LIMIT_QUOTA_ECOMMERCE(state, payload) {
    state.isOpenModalLimitQuota = payload
  },
}
